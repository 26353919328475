import React from 'react';
import APILink from '../APILink/APILink.js'

const Sidebar = props => {
    let apiLinks = []
    let organizationConfig = props.organizationConfig

    if(props.definitionList.length > 0){
        for (let i = 0; i < props.definitionList.length; i++) {
            apiLinks.push(
                <APILink 
                    key={i}
                    apiLinkData={props.definitionList[i]}
                    updateDefinitionLink={props.updateDefinitionLink}
                    schemaDefinition={props.schemaDefinition}
                />
            )
            
        }
    }

  return (
    <div className="side-bar">
        <div className="side-bar-header">
            <img src={organizationConfig.displayImage} alt="logo"/>
        </div>
        <div className="side-bar-body">
            {apiLinks}
        </div>
    </div>
  )
}

export default Sidebar;